:root {
	--azul: #232F3E;
	--rojo: #C74634;
	--plomo-claro: #F3F3F3;
	--plomo-oscuro: #E6E9E9;
	--plomo-mas-oscuro: #C4C4C4;
	--aws-ligth: #F2F4F4;
}

@font-face {
  font-family: Manrope;
  src: url(./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf);
}

@font-face {
  font-family: Raleway;
  src: url(./assets/fonts/Raleway/Raleway-VariableFont_wght.ttf);
}

@font-face {
  font-family: Sora;
  src: url(./assets/fonts/Sora/Sora-VariableFont_wght.ttf);
}

.font-manrope {
	font-family: "Manrope", sans-serif !important;
}

.font-manrope-bold {
	font-family: "Manrope", sans-serif !important;
	font-weight: bold !important;
}

.font-raleway {
	font-family: "Raleway", sans-serif !important;
}

.font-raleway-bold {
	font-family: "Raleway", sans-serif !important;
	font-weight: bold !important;
}

.font-sora {
	font-family: "Sora", sans-serif !important;
}

.font-sora-bold {
	font-family: "Sora", sans-serif !important;
	font-weight: bold !important;
}

.font-size-small {
	font-size: 14px !important;
}

.font-size-medium {
	font-size: 18px !important;
}

.font-size-large {
	font-size: 24px !important;
}

