@font-face {
  font-family: Raleway;
  src: url(../../assets/fonts/Raleway/Raleway-VariableFont_wght.ttf);
}

.clients-responsive {
  width: 180px;
  height: auto;
}
.clients-responsive2 {
  width: auto;
  height: 33px;
}
.clients-responsive3 {
  width: auto;
  height: 80px;
}
.clients-responsive4 {
  width: auto;
  height: 70px;
}
.clients-responsive5 {
  width: auto;
  height: 50px;
}
.clients-responsive6 {
  width: auto;
  height: 40px;
}
.clients-tittle {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
