@font-face {
  font-family: Raleway !important;
  src: url(../../assets/fonts/Raleway/Raleway-VariableFont_wght.ttf) !important;
}

@font-face {
  font-family: Sora !important;
  src: url(../../assets/fonts/Sora/Sora-VariableFont_wght.ttf) !important;
}

@font-face {
  font-family: Manrope !important;
  src: url(../../assets/fonts/Manrope/Manrope-VariableFont_wght.ttf) !important;
}

.box-welcome {
  font-size: 36px;
  height: 160px;
  overflow: hidden;
}

.welcome-text {
  top: 25%;
  position: relative;
  transition: all 0.8s ease-out;
  height: 100%;
}

/* .box-welcome:hover > .welcome-text {
  top: 0;
} */

.MuiMobileStepper-dots {
  display: none !important;
}

@media (max-width: 678px) {
  .box-welcome {
    font-size: 23px;
  }
}

.tsparticles-canvas-el {
  max-height: 62% !important;
}

.container-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.chevron {
  position: absolute;
  width: 2.0rem;
  height: 0.28rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #232F3E;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@keyframes move-chevron {
  25% {
    opacity: 0.3;
  }
  33.3% {
    opacity: 0.6;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

.particles-container{
  width: 100% !important;
  background-color: #232F3E !important;
  position: absolute !important;
  height: 75% !important
}
