.App {
    text-align: center;
    height: 100%;
}

.container {
    padding: 0  0 3.5rem 0;
}

.slide {
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
}

.slideWrapper {
    display: flex;
    justify-content: center;
}

.prevArrow {
    position: absolute;
    top: 40%;
    left: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
}

.nextArrow {
    position: absolute;
    top: 40%;
    right: 60px;
    z-index: 100;
    cursor: pointer;
    font-size: 2rem;
}

.title{
    padding-top: 20px;
}

@media (max-width: 520px){
    .prevArrow {
        left: 0px;
    }
    .nextArrow {
        right: 0px;
    }
}

@media (max-width: 390px){
    .prevArrow {
        left: 0px;
    }
    .nextArrow {
        right: 0px;
    }
    .color {
        color: var(--rojo);
    }
}

